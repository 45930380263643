<template>
  <div class="page">
    <div class="title_box">签名</div>
    <div class="sign_box">
      <div class="in_sign_box" ref="canvasHW">
        <signCanvas
          ref="esign"
          :width="800"
          :height="354"
          :isCrop="isCrop"
          :lineWidth="lineWidth"
          :lineColor="lineColor"
          :bgColor.sync="bgColor"
        />
      </div>
    </div>
    <div class="title_box">意见建议</div>
    <div class="opinion_box">
      <div class="in_opinion_box">
        <van-field
          style="background: transparent; height: 100% !important"
          v-model="message"
          type="textarea"
          maxlength="30"
          placeholder="请填写您的意见建议"
          rows="4"
          show-word-limit
        />
      </div>
    </div>
    <!-- 提交按钮 -->
    <div class="btn_box">
      <div class="submit" @click="handleGenerate">提交</div>
    </div>
  </div>
</template>

<script>
import { signTheContractUrl } from "./api.js";
import { base64toFile } from "@/utils/utils.js";
import signCanvas from "@/components/bussiness/signCanvas";
export default {
  name: "signNeighborhoodPact",
  data() {
    return {
      message: "",
      isCrop: false,
      lineWidth: 6,
      lineColor: "#979797",
      bgColor: "#D8D8D8",
      uploadUrl: "/gateway/blade-resource/oss/endpoint/put-file",
      signImageUrl: "",
    };
  },
  components: {
    signCanvas,
  },
  computed: {
    userId() {
      return this.$store.state.userId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  methods: {
    async postSignData() {
      let params = {
        userId: this.userId,
        signaturePicture: this.signImageUrl,
        opinionsSuggestions: this.message,
        tenantId: this.tenantId,
      };
      let res = await this.$axios.post(`${signTheContractUrl}`, params);
      if (res.code === 200) {
        console.log(res);
        this.$toast({
          message: res.msg,
          duration: 2000,
        });
        this.$router.go(-1);
      } else {
      }
    },
    async uploadImg(file) {
      var formData = new FormData();
      formData.append("file", file);
      let res = await this.$axios.post(`${this.uploadUrl}`, formData);
      if (res.code == 200) {
        this.signImageUrl = res.data.link;
        this.postSignData();
        console.log(this.signImageUrl);
      } else {
      }
    },
    handleGenerate() {
      if (this.message == "") {
        this.$toast({
          message: "签名/意见建议不能为空！",
          duration: 2000,
        });
      } else {
        this.$refs.esign
          .generate()
          .then((res) => {
            this.resultImg = res;
            console.log(base64toFile(this.resultImg), "009");
            this.uploadImg(base64toFile(this.resultImg));
          })
          .catch((err) => {
            alert(err); // 画布没有签字时会执行这里 'Not Signned'
          });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  width: 100%;
  min-height: 100vh;
  background: #fff;

  .btn_box {
    box-sizing: border-box;
    height: 66px;
    padding: 0 100px;
    position: fixed;
    bottom: 122px;
    left: 0;
    right: 0;

    .submit {
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, #9591ff 0%, #605cff 100%);
      border-radius: 10px;
      font-size: 30px;
      text-align: center;
      line-height: 66px;
      font-weight: 600;
      color: #ffffff;
    }
  }

  .opinion_box {
    width: 100%;
    padding: 0 45px;
    // height: 308px;
    box-sizing: border-box;

    .in_opinion_box {
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.04);
      border-radius: 16px;
    }
  }

  .sign_box {
    width: 100%;
    padding: 0 45px;
    // height: 256px;
    box-sizing: border-box;

    .in_sign_box {
      width: 100%;
      margin-bottom: 20px;
      // height: 100%;
      // background: #D8D8D8;
    }
  }

  .title_box {
    width: 100%;
    font-size: 36px;
    font-weight: 600;
    color: #333333;
    padding: 22px 44px;
    box-sizing: border-box;
  }
}
</style>
